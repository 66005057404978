import Vue from "vue";

Vue.filter('currency', function (value) {
  return 'CC ' + parseFloat(value).toLocaleString(
    process.env.VUE_APP_LOCALE,
    {
      style: 'decimal',
      minimumFractionDigits: 2
    }
  )
})